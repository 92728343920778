import {useRouter} from "#imports";
import {LocationQueryValue, RouteParamValue} from "vue-router";

export const buildContinueUrl = (
    checkoutId: string | RouteParamValue[],
    token: string | null | LocationQueryValue[],
    to?: string | null,
): string => {
    const config = useRuntimeConfig();

    return config.public.url + useRouter().resolve({
        name: 'checkout-continue',
        query: {
            'checkout_id': checkoutId,
            'checkout_token': token,
            ...(!!to ? {to} : {}),
        }
    }).href;
}